@import '../../styles/breakpoints';
@import '../../styles/dimensions';
@import '../../styles/font-sizes';

.container {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: $gap-xx-large auto;
    min-height: 768px;
    padding: $gap-x-large;
    position: relative;
    z-index: 1;

    @include screen-max-width('lg') {
        margin: $gap-x-large auto;
        min-height: 768px;
        padding: $gap-x-large $gap-small;
    }
    @include screen-max-width('sm') {
        margin: $gap-small-medium auto;
        min-height: 768px;
        padding: $gap-small-medium $gap-small;
    }

    &.noCopy {
        margin: 0 auto;
    }
}

.copyContainer {
    align-items: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: $gap-medium;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
}

.copyTop {
    display: flex;
    position: relative;
    top: -40px;

    @include screen-max-width('lg') {
        top: -40px;
    }
    @include screen-max-width('md') {
        top: 20px;
    }
    @include screen-max-width('sm') {
        top: 60px;
    }
}

.copyBottom {
    align-self: flex-end;
    bottom: -40px;
    display: flex;
    position: relative;

    @include screen-max-width('lg') {
        bottom: -40px;
    }
    @include screen-max-width('sm') {
        bottom: 50px;
    }
    @include screen-max-width('xs') {
        bottom: 80px;
    }
}

.copyTop div div div,
.copyBottom div div div {
    @include screen-max-width('lg') {
        font-size: 42px;
    }
    @include screen-max-width('md') {
        font-size: 38px;
    }
    @include screen-max-width('sm') {
        font-size: 32px;
    }
}

.mediaContainer {
    aspect-ratio: 949/1016;
    display: flex;
    position: relative;
    width: 713px;
    z-index: 2;

    &.image {
        max-width: 949px;
    }

    @include screen-max-width('md') {
        width: 100% !important;
    }
}

.image {
    border-radius: $gap-medium;
    height: auto !important;
}

.video {
    border-radius: $gap-medium;
    height: auto !important;
    object-fit: cover;
    width: 100% !important;
}

.disclaimer {
    margin-top: 12px;
    text-align: center;
    width: 100%;

    @include screen-max-width('lg') {
        text-align: left;
    }
}
